import { IonButton, isPlatform } from "@ionic/react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import clsx from "clsx";
import { Fragment, ReactNode, forwardRef } from "react";
import { AlertAction } from "./FaAlertContext";

type FaAlertContentProps = {
    onDismiss: () => void;
    /** @default true */
    backdropDismiss?: boolean;

    title?: string;
    message: ReactNode;

    actions?: AlertAction[];

    className?: string;
};

export const FaAlertContent = forwardRef<HTMLDivElement, FaAlertContentProps>((props, forwardedRef) => {
    return (
        <Fragment>
            <AlertDialog.Overlay
                className="fa-alert__overlay"
                onClick={event => {
                    event.preventDefault();
                    if (props.backdropDismiss !== false) {
                        props.onDismiss();
                    }
                }}
            />
            <AlertDialog.Content
                data-fa-popover-target
                className={clsx(
                    "fa-alert__content",
                    isPlatform("mobile") && "fa-modal__content--mobile",
                    props.className
                )}
                onClick={event => event.preventDefault()}
                ref={forwardedRef}>
                {props.title && <AlertDialog.Title className="fa-alert__title">{props.title}</AlertDialog.Title>}
                <AlertDialog.Description className="fa-alert__message">{props.message}</AlertDialog.Description>
                <div className="fa-alert__actions">
                    {props.actions && props.actions.length > 0 ? (
                        props.actions.map(action => (
                            <IonButton
                                onClick={() => {
                                    action.handler?.();
                                    props.onDismiss();
                                }}
                                color={action.color}
                                key={action.text}>
                                {action.text}
                            </IonButton>
                        ))
                    ) : (
                        <AlertDialog.Action asChild>
                            <IonButton>Ok</IonButton>
                        </AlertDialog.Action>
                    )}
                </div>
            </AlertDialog.Content>
        </Fragment>
    );
});
